<template>
  <div class="home">
    <div class="home_top">
      <img class="bg pc-show" src="@/assets/image/dreambg1.png" alt="" />
      <headerTop title="%追梦" readme="玩法说明" @handleReadme="handleilltate" />
      <div class="header">
        <div class="header_left" @click="getDeramlist">
          <img v-if="targetornaments?.imageUrl" :src="targetornaments?.imageUrl" alt width="100%" />
          <i v-else class="plus_icon el-icon-circle-plus-outline"></i>
          <span>{{ targetornaments?.shortName || '选择目标饰品' }}</span>
        </div>

        <div class="header_main center">
          <div class="header_circle">
            <el-progress type="circle" stroke-linecap="butt" :color="$main" define-back-color="#5C5C5C" :percentage="value3" :stroke-width="5" />
            <svg width="100%" height="100%">
              <defs>
                <linearGradient id="yellow" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" style="stop-color: #00f9ff" stop-opacity="1"></stop>
                  <stop offset="50%" style="stop-color: #25f484" stop-opacity="1"></stop>
                  <stop offset="100%" style="stop-color: yellow" stop-opacity="1"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="header_center center" :style="`${onOpen ? `transform: rotate(${successRateTow}deg);transition: transform 6s ease-out;` : ''}`"></div>
          <div class="header_center_c1 center"></div>
          <div class="header_center_c2">
            <p class="count"><countTo :startVal="oldValue3" :endVal="value3" :duration="1200" /><span>%</span></p>
            <p class="tip">追梦成功率</p>
          </div>
        </div>
        <div class="header_right">
          <img src="@/assets/image/random.png" alt />
          <div class="mobile-show">随机得到饰品</div>
        </div>
      </div>
      <div class="main">
        <el-slider v-model="value3" :show-tooltip="false" :min="5" :max="75" :marks="{ 5: '5%', 75: '75%' }">
          <div>{{ value3 }}</div>
        </el-slider>
        <div v-if="targetornaments" class="main_center center btn_click" @click="handleDeramupgrade">
          <div class="center">
            花费 &nbsp;
            <price :price="luckeyMoney" :size="$mode == 'pc' ? 'large' : 'middle'" />
            &nbsp; 试试运气
          </div>
        </div>
        <div class="main_center center btn_click" v-else @click="getDeramlist">
          <div>请选择饰品</div>
        </div>
      </div>
    </div>
    <div class="home_top_cover" v-if="showCover"></div>
    <!-- 选择饰品 -->
    <div class="out" v-if="showSelect" @click.stop="isqulity = false">
      <div :class="['out_box', 'animate__animated', $store.state.mode == 'pc' ? 'animate__fadeIn' : 'animate__slideInUp']" ref="selectbox">
        <div class="out_title space_between">
          <div></div>
          <i class="close el-icon-close" @click="handleCloseSelect"></i>
        </div>
        <div class="out_top space_between">
          <div class="price_input center">
            <input type="text" v-model="queryParams.priceMin" placeholder="价格最小值" />
            <div style="color: #888; margin: 0 4px">-</div>
            <input type="text" v-model="queryParams.priceMax" placeholder="价格最大值" />
          </div>
          <div
            class="price_btn center btn_click"
            @click="
              handleSearch();
              playAudio();
            "
          >
            筛选
          </div>
        </div>
        <div class="out_center space_between">
          <div class="out_type center" @click.stop="handleSelectType">
            {{ weaponetype }}
            <i class="el-icon-arrow-down" style="margin-left: 5px; font-weight: bold"></i>
            <div v-if="isqulity" class="out_type_select">
              <div class="out_type_select_btn center" :class="queryParams.type == '' ? 'active' : ''" @click.stop="handleQuality(0)">默认</div>
              <div class="out_type_select_btn center" v-for="(item, index) in qualityList" :key="index" :class="queryParams.type == Object.values(item)[0] ? 'active' : ''" @click.stop="handleQuality(item)">
                <div class="xl_btn">{{ Object.keys(item)[0] }}</div>
              </div>
            </div>
          </div>
          <div class="out_center_right">
            <input type="text" v-model="queryParams.itemName" style="serch" placeholder="请输入饰品名称" />
            <i @click="handleSearch" class="icon el-icon-search"></i>
          </div>
        </div>
        <div class="out_bottom" v-loading="serchLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="out_bottom_list" v-if="omlist.length">
            <goodsCard v-for="(item, index) in omlist" :key="index" :goodsItem="item" :isCheck="true" :keyMap="{ price: 'usePrice' }" @handleClick="handleSelect" />
          </div>
          <div class="not_data" v-else>--暂无饰品--</div>
        </div>
      </div>
    </div>
    <div class="fall" v-if="isResult">
      <div id="fall_weapon" ref="myElement" class="fall_weapon animate__animated animate__fadeInDown column">
        <div v-if="success">
          <div class="fall_name">{{ isVictory ? '恭喜获得' : '就差一点，再接再厉~~~' }}</div>
          <goodsCard :goodsItem="resultornaments" :isCheck="true" :keyMap="{ name: 'ornamentName', levelImg: 'ornamentLevelImg', imageUrl: 'ornamentImgUrl', price: 'ornamentPrice' }" />
        </div>
        <div v-else class="fail">
          <div>{{ resultornaments }}</div>
          <img src="fail.png" alt="" />
        </div>
        <div @click="handleClose" class="fall_btn center btn_click">确定</div>
      </div>
    </div>
    <div @click="handleType" class="type_btn center">历史掉落</div>
    <div class="table_box" id="dreamTable">
      <table class="table" cellspacing="0" cellpadding="1" border="none" width="100%">
        <tr class="table_header">
          <th>昵称</th>
          <th>获得</th>
          <th>价值</th>
          <th>成功率</th>
          <th>结果</th>
          <th>时间</th>
        </tr>
        <template v-if="recordList.length">
          <tr class="table_body" v-for="item in recordList" :key="item.id">
            <th class="name">{{ item.nickName }}</th>
            <th
              class="img"
              :style="{
                background: `url(${JSON.parse(item.gainOrnamentList)[0].ornamentLevelImg}) center  no-repeat`,
                'background-size': '100% 70%',
              }"
              v-if="item.gainOrnamentList"
            >
              <img :src="JSON.parse(item.gainOrnamentList)[0].ornamentImgUrl" alt width="100%" />
            </th>
            <th v-else class="img">
              <img src="" alt width="100%" />
            </th>
            <th><price :price="item.gainOrnamentsPrice" /></th>
            <th>{{ item.probability + '%' }}</th>
            <th>
              <div :style="{ color: item.isVictory ? $main : 'red' }">{{ item.isVictory ? '成功' : '失败' }}</div>
            </th>
            <th class="time">{{ item.openTime }}</th>
          </tr>
        </template>
      </table>
      <div class="not_data" v-if="!recordList.length">--暂无数据--</div>
    </div>
  </div>
</template>

<script>
import { deramlist, deramrecord, deramupgrade, shopcondition, getUserInfo, getUpgradeComputePrice } from '@/api/index';
import countTo from 'vue-count-to';
import { Loading } from 'element-ui';
import goodsCard from '@/components/goodsCard';

export default {
  components: { countTo, goodsCard },
  data() {
    return {
      showCover: false,
      success: true,
      isVictory: false,
      serchLoading: false,
      isqulity: false,
      weaponetype: '类别',
      successRateTow: 3600,
      onOpen: false,
      isResult: false,
      targetornaments: null,
      resultornaments: null,
      showSelect: false,
      value3: 0,
      oldValue3: 0,
      pkAudio: new Audio(require('../../../public/2.mp3')),
      omlist: [],
      recordList: [],
      qualityList: [],
      queryParams: {
        itemName: '',
        priceMax: null,
        priceMin: null,
      },
      timer: null,
      recordLoading: null,
      luckeyMoney: 0,
      value3Timer: null,
    };
  },
  computed: {
    // luckeyMoney() {
    //   let bean = 0;
    //   bean = (this.targetornaments?.usePrice * this.value3) / 100;
    //   return bean.toFixed(2);
    // },
  },
  mounted() {
    let timer = setTimeout(() => {
      this.value3 = 25;
      clearTimeout(timer);
    }, 100);
    this.pollingList({ isVictory: true });
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  watch: {
    value3(newVal, oldVal) {
      this.oldValue3 = oldVal;
      if (this.value3Timer) {
        clearTimeout(this.value3Timer);
        this.value3Timer = null;
      }
      this.value3Timer = setTimeout(() => {
        this.getUpgradeComputePrice();
      }, 100);
    },
  },
  methods: {
    getUpgradeComputePrice() {
      getUpgradeComputePrice({
        upgradeOrnamentId: this.targetornaments.id,
        probability: this.value3,
      }).then(res => {
        this.luckeyMoney = res.data.data;
      });
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleilltate() {
      this.playAudio();
      this.$bus.$emit('is_illustrate', true);
      let timer = setTimeout(() => {
        this.$bus.$emit('nr_illustrate', { cont: 'dream' });
        clearTimeout(timer);
      }, 100);
    },
    handleQuality(item) {
      this.playAudio();
      let res = Number(Object.values(item)[0]);
      this.weaponetype = Object.keys(item)[0];
      if (item == 0) {
        this.weaponetype = '类别';

        this.queryParams = {};
        this.handleSearch();
      } else {
        this.queryParams.type = res;
        this.handleSearch();
      }
      this.isqulity = false;
    },
    handleSelectType() {
      this.playAudio();
      this.isqulity = true;
      shopcondition(0).then(res => {
        this.qualityList = res?.data?.data || [];
      });
    },
    handleCloseSelect() {
      this.playAudio();
      this.weaponetype = '类别';
      const element = this.$refs.selectbox; // 获取元素对象
      element.className += this.$store.state.mode == 'pc' ? ' animate__fadeOut' : ' animate__slideOutDown';
      let timer = setTimeout(() => {
        this.showSelect = false;
        clearTimeout(timer);
      }, 500);
    },
    handleClose() {
      this.playAudio();
      const element = this.$refs.myElement; // 获取元素对象
      if (element) element.className += ' animate__fadeOutDown';
      let timer = setTimeout(() => {
        this.isResult = false;
        clearTimeout(timer);
        if (this.targetornaments.ornamentId) {
          this.pollingList({ ornamentId: this.targetornaments.ornamentId });
        } else {
          this.pollingList({ isVictory: true });
        }
      }, 500);
    },
    handleSelect(res) {
      this.handleCloseSelect();
      this.targetornaments = res;
      this.queryDeramrecord({ ornamentId: res.ornamentId });
      this.getUpgradeComputePrice();
    },
    handleType() {
      this.playAudio();
      this.queryDeramrecord({ isVictory: true });
      this.recordLoading = Loading.service({ target: '#dreamTable' });
    },
    handleSearch() {
      this.serchLoading = true;
      deramlist(this.queryParams).then(res => {
        this.omlist = res?.data?.data || [];
        let timer = setTimeout(() => {
          this.serchLoading = false;
          clearTimeout(timer);
        }, 350);
      });
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 0.7) + min + 0.2);
    },
    handleDeramupgrade() {
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.playAudio();
      const { accountAmount, accountCredits, isRealCheck } = this.$store.state.USER_INFO;
      if (accountAmount + accountCredits < this.luckeyMoney) {
        this.$message({
          offset: 50,
          message: '余额不足，请充值',
          type: 'warning',
        });
        return false;
      }
      if (isRealCheck == '0') {
        this.$message({
          offset: 50,
          message: '请先进行实名认证',
          type: 'warning',
        });
        return false;
      }

      let obj = {
        upgradeOrnamentId: this.targetornaments.id,
        price: this.luckeyMoney,
        probability: this.value3,
      };
      deramupgrade(obj).then(res => {
        const { code, msg, data } = res?.data || {};
        if (code == 500) {
          this.$message({
            offset: 50,
            message: msg,
            type: 'warning',
          });
          return false;
        }
        this.onOpen = true;
        this.pkAudio.play();
        this.showCover = true;
        // 消费后需要更新账户余额
        getUserInfo().then(result => {
          this.$store.commit('USER_INFO', result?.data?.data || {});
        });
        const { gainOrnamentList, isVictory } = data || {};
        console.log('gainOrnamentList', gainOrnamentList);
        console.log('isVictory', isVictory);
        console.log('data', data);

        this.resultornaments = gainOrnamentList ? JSON.parse(gainOrnamentList)[0] : '失败啦，啥都没得到~';
        if (gainOrnamentList && this.targetornaments.usePrice == this.resultornaments.ornamentPrice) {
          this.successRateTow = 360 * 15 + 360 * (this.value3 / 100) - Math.floor(Math.random() * (360 * (this.value3 / 100)));
        } else {
          this.successRateTow = 360 * 15 + 360 * (this.value3 / 100) + this.getRandomNumber(360 * (this.value3 / 100) + 1, 360) - 360 * (this.value3 / 100);
        }
        let timer = setTimeout(() => {
          this.onOpen = false;
          this.isResult = true;
          this.showCover = false;
          this.isVictory = isVictory;
          this.success = Boolean(gainOrnamentList);
          clearTimeout(timer);
        }, 6500);
      });
    },
    pollingList(data) {
      if (this.$store.state.LOGIN_IS_SHOW) return;
      this.timer && clearInterval(this.timer);
      this.queryDeramrecord(data);
      this.timer = setInterval(() => {
        this.queryDeramrecord(data);
      }, 5000);
    },
    queryDeramrecord({ isVictory, ornamentId }) {
      if (this.$store.state.LOGIN_IS_SHOW) return;
      let data = {};
      if (isVictory) {
        data = { page: 1, size: 10, isVictory };
      } else {
        data = { page: 1, size: 10, ornamentId };
      }
      deramrecord(data).then(res => {
        let timer = setTimeout(() => {
          this.recordLoading?.close();
          clearTimeout(timer);
        }, 500);
        if (res?.data?.code != 200) return;
        const list = res?.data?.data.list || [];
        this.recordList = [...list];
        if (ornamentId) clearInterval(this.timer);
      });
    },
    getDeramlist() {
      this.playAudio();
      this.showSelect = true;
      deramlist().then(res => {
        this.omlist = res?.data?.data || [];
      });
    },
    handleodds(res) {
      this.value3 = Number(res);
    },
  },
};
</script>

<style lang="less" scoped>
.type_btn {
  width: 140px;
  height: 42px;
  margin: 0 auto;
  margin-top: -70px;
  .sc(18px, #fff);
  font-weight: bold;
  border-radius: 70px;
  position: relative;
  background: linear-gradient(-270deg, #25f484 0%, @main 100%);
  @media @max750 {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin-top: -56px;
  }
}

.tooltip-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #409eff;
  color: #fff;
  border-radius: 50%;
}
@keyframes my {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep .el-slider {
  width: 30%;
  @media @max750 {
    width: 70%;
  }
}
::v-deep .el-slider__button-wrapper {
  top: -6px;
  z-index: 2;
  width: auto;
  height: 26px;
  border-radius: 50%;
  @media @max750 {
    top: -7px;
    height: 20px;
  }
}
::v-deep .el-slider__runway {
  margin: 0;
  height: 14px;
  border-radius: 50px;
  background: rgba(50, 52, 54, 1);
  @media @max750 {
    height: 12px;
  }
}
::v-deep .el-slider__stop {
  display: none;
}
::v-deep .el-slider__marks-text {
  .sc(18px, #fff);
  transform: translate(-50%, -60px);
  @media @max750 {
    font-size: 16px;
    &:first-of-type {
      transform: translate(-150%, -20px);
    }
    &:last-of-type {
      transform: translate(40%, -20px);
    }
  }
}
::v-deep .el-slider__bar {
  height: 100%;
  border-radius: 20px 0 0 20px;
  background: linear-gradient(-270deg, yellow 0%, #25f484 50%, @main 100%);
}
// 拖动的滑块的样式
::v-deep .el-slider__button {
  background: @main;
  width: 18px;
  height: 100%;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 10px 0 @main;
}

.home {
  margin: 0 @pcSpace;
  @media @max750 {
    margin: 0 @mSpace;
  }
}
.home_top_cover {
  width: 100%;
  height: 700px;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 999;
}
.home_top {
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: relative;
  margin-top: -50px;
  background: url(../../assets/image/dreambg.png) no-repeat;
  background-size: 100% 100%;
  @media @max750 {
    height: 430px;
    margin-top: 0;
    background: none;
  }
  .bg {
    left: 3%;
    bottom: 13%;
    width: 30%;
    height: auto;
    position: absolute;
  }
}
.header_top {
  margin-top: 74px;
  @media @max750 {
    margin-top: 0;
  }
}
.header {
  width: 100%;
  display: flex;
  margin-top: 12px;
  justify-content: center;
  @media @max750 {
    margin-top: 10px;
    align-items: flex-end;
  }
  .header_left,
  .header_right {
    width: 300px;
    height: 340px;
    z-index: 2;
    position: relative;
    font-size: 14px;
    .flex-column-center;
    background: url(../../assets/image/roundbg.png) no-repeat;
    background-size: 100% 100%;
    @media @max750 {
      width: auto;
      height: auto;
      font-size: 10px;
      background: none;
      flex: 1;
    }
    & > img {
      .wh(18%, auto);
      margin: 20px 10px 0 0;
      transform: rotate(30deg);
      @media @max750 {
        width: 30%;
        margin: 0 0 6px 0;
      }
    }
  }
  .header_left {
    font-size: 16px;
    color: #fff;
    & > img {
      width: 30%;
      transform: rotate(10deg);
    }
    span {
      white-space: nowrap;
      padding-right: 20px;
    }
    .plus_icon {
      font-size: 40px;
      padding: 20px 10px 10px 0;
    }
    @media @max750 {
      font-size: 10px;
      span {
        padding-right: 0;
      }
      img {
        width: 80%;
      }
      .plus_icon {
        padding: 20px 0 6px;
      }
    }
  }
}
.header_main {
  position: relative;
  width: 310px;
  height: 310px;
  margin: 0 2%;
  &::before {
    content: '';
    .abs-ctl;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: @main;
    filter: blur(20px);
    animation: inlineSun 3s ease infinite;
  }
  @media @max750 {
    width: 200px;
    height: 200px;
    margin: 0 10px;
  }
  .header_circle {
    overflow: hidden;
    position: relative;
    &::v-deep {
      position: absolute;
      width: 296px;
      height: 296px;
      @media @max750 {
        width: 195px;
        height: 195px;
      }
      .el-progress-circle {
        svg > path {
          stroke: transparent;
        }
        svg > path:nth-child(2) {
          stroke: url(#yellow) !important;
        }
      }
      .el-progress-bar__outer {
        background-color: rgba(0, 0, 0, 0.15);
      }
      .el-progress {
        width: 100%;
        height: 100%;
        .el-progress-circle {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  .header_center {
    width: 300px;
    height: 300px;
    box-shadow: 0px 0 14px 0px @main;
    opacity: 1;
    border: 1px solid #323436;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    @media @max750 {
      width: 200px;
      height: 200px;
    }
  }
  .header_center_c1 {
    width: 190px;
    height: 190px;
    background: #262729;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    @media @max750 {
      width: 182px;
      height: 182px;
    }
  }
  .header_center_c2 {
    position: absolute;
    width: 190px;
    height: 190px;
    background: url(../../assets/image/centerbg.png) no-repeat;
    background-size: 100% 100%;
    box-shadow: 0px 0 14px 0px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 48px;
    @media @max750 {
      width: 153px;
      height: 153px;
      font-size: 30px;
    }
    .count {
      .wh(62%, 62%);
      .flex-center;
      color: #fff;
      font-weight: bolder;
      background: #262729;
      border-radius: 50%;
      margin-top: -40px;
    }
    .tip {
      font-size: 14px;
      color: #ccc;
      margin-top: -30px;
      @media @max750 {
        font-size: 10px;
      }
    }
  }
  .header_center::before {
    content: ''; /* 必需属性，表示生成内容为空 */
    .abs-cl;
    .wh(4px, 32px);
    top: 0;
    background: @main;
    @media @max750 {
      .wh(3px, 24px);
    }
  }
}
.main {
  margin: 0 auto;
  .flex-column-center;
  @media @max750 {
    margin-top: 30px;
  }
  .main_top {
    height: 42px;
    background: #323436;
    border-radius: 70px;
    opacity: 1;
    font-weight: 700;
    @media @max750 {
      height: 30px;
      font-size: 13px;
    }
    .main_top_btn {
      width: 84px;
      height: 35px;
      color: rgba(114, 116, 119, 1);

      @media @max750 {
        height: 25px;
      }
    }
    .active {
      background: linear-gradient(-270deg, #25f484 0%, @main 100%);
      border-radius: 70px 70px 70px 70px;
      color: #fff;
      font-weight: bold;
      transition: all 0.3s ease;
      @media @max750 {
        height: 25px;
      }
    }
  }
  .main_center {
    .btn-bg;
    margin-top: 32px;
    padding: 3px 14px;
    font-size: 18px;
    transition: all 0.3s ease;
    @media @max750 {
      font-size: 16px;
      margin-top: 22px;
    }
    &:hover {
      opacity: 1;
    }
  }
}
.out {
  .fixed_bg;
  z-index: 2;
  .out_box {
    .abs-ctl;
    width: 60%;
    height: auto;
    padding: 20px 0;
    border-radius: 20px;
    background: @bg1;
    @media @max750 {
      top: 0;
      left: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
  .out_title {
    padding: 0 20px;
    @media @max750 {
      padding: 10px 10px 0 10px;
    }
  }
  .close {
    font-size: 20px;
    @media @max750 {
      font-size: 16px;
    }
  }
  .out_top {
    padding: 20px;
    @media @max750 {
      padding: 10px;
    }
  }
  .price_input {
    position: relative;
    z-index: 5;
    input {
      width: 148px;
      height: 44px;
      border: none;
      color: #fff;
      font-size: 18px;
      border-radius: 50px;
      background-color: #323436;
      text-align: center;
      outline: none;
      @media @max750 {
        width: 106px;
        height: 32px;
        border-radius: 26px;
        font-size: 14px;
      }
    }
    input::-webkit-input-placeholder {
      @media @max750 {
        font-size: 14px;
      }
    }
  }
  .price_btn {
    .btn-bg;
    width: 148px;
    height: 44px;
    opacity: 1;
    @media @max750 {
      width: 106px;
      height: 32px;
      font-size: 16px;
    }
  }
  .out_center {
    padding: 0 20px;
    @media @max750 {
      padding: 0 10px;
    }
    .out_type {
      width: 118px;
      height: 44px;
      background: #323436;
      border-radius: 74px 74px 74px 74px;
      opacity: 1;
      position: relative;
      @media @max750 {
        width: 85px;
        border-radius: 53px;
        font-size: 12px;
        height: 32px;
      }
      .out_type_select {
        position: absolute;
        top: 46px;
        left: 0;
        z-index: 5;
        background: rgba(16, 17, 21, 1);
        width: 100%;
        @media @max750 {
          top: 36px;
        }
      }
      .out_type_select_btn {
        width: 100%;
        height: 44px;
        @media @max750 {
          height: 30px;
        }
        &:hover {
          color: @main;
        }
      }
      .active {
        color: @main;
      }
    }
    .out_center_right {
      flex: 1;
      height: 42px;
      margin-left: 2%;
      padding: 0 10px;
      background: #323436;
      border-radius: 70px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      @media @max750 {
        height: 32px;
      }
      .icon {
        padding: 5px;
        font-size: 24px;
        @media @max750 {
          font-size: 18px;
        }
      }
      input {
        width: 100%;
        height: 100%;
        border-radius: 35px;
        border: 1px solid #323436;
        color: #fff;
        font-size: 16px;
        background-color: #323436;
        text-indent: 10px;
        outline: none;
        @media @max750 {
          font-size: 14px;
        }
      }
    }
  }
  .out_foot {
    background: #101115;
    border-radius: 15px 15px 15px 15px;
    text-align: center;
    height: 665px;
  }
  .out_bottom {
    opacity: 1;
    height: 50vh;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 15px;
    text-align: center;
    @media @max750 {
      height: 565px;
      padding: 10px;
    }
    .out_bottom_list {
      .grid(6, 10px);
      @media @max750 {
        .grid(3, 6px);
      }
      ::v-deep .goods_name {
        padding-bottom: 0;
      }
    }
    .out_bottom_weapone {
      width: 100%;
      padding: 10px 0;
      background: @bg1;
      border-radius: 14px 14px 14px 14px;
      background-size: 100% 100%;
      transition: all 0.3s linear;
      font-size: 14px;
      @media @max750 {
        font-size: 10px;
        padding: 6px 0;
      }
      &:hover {
        opacity: 1;
        img {
          transition: all 0.3s linear;
          transform: translateY(-5px);
        }
      }
      &:not(:hover) {
        opacity: 0.8;

        img {
          transition: all 0.3s linear;
          transform: translateY(0px);
        }
      }
    }
    .out_bottom_img {
      width: 109px;
      height: 86px;
      margin: 0 auto;
      @media @max750 {
        width: 78px;
        height: 61px;
      }
    }
  }
}
.fall {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .flex-center;
  .fall_name {
    font-size: 26px;
    text-align: center;
    @media @max750 {
      font-size: 22px;
    }
  }
  .fall_weapon {
    width: 34%;
    margin: 0 auto;
    padding: 30px 0;
    position: relative;
    background: #101115;
    border-radius: 23px;
    justify-content: space-around;
    overflow: hidden;
    @media @max750 {
      width: 94%;
    }
  }
  .fall_weapon::before {
    content: ''; /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(-219deg, rgba(@main, 0.2) 0%, rgba(32, 29, 48, 0) 40%, rgba(248, 125, 81, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
  }

  .goods_item {
    width: 100%;
    margin: 20px 0;
    ::v-deep .goods_img {
      height: 180px;
      @media @max750 {
        height: 120px;
      }
    }
    ::v-deep .goods_bottom {
      padding: 10px 0;
    }
  }

  .fall_btn {
    .btn-bg;
    width: 50%;
    margin: 0 auto;
    height: 46px;
    font-size: 22px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      transition: all 0.3s linear;
    }
    @media @max750 {
      height: 32px;
      font-size: 16px;
    }
  }
}
table {
  border-collapse: separate;
  border-spacing: 0 10px; /* 设置行间距为10像素 */
}
tr {
  border-bottom: 10px solid transparent; /* 添加透明的底部边框来模拟间距 */
}
.table {
  border: transparent;
  text-align: center;
  tr {
    th:first-child {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }
    th:last-child {
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }
  .table_header {
    border: transparent;
    height: 70px;
    background: rgba(16, 17, 21, 1);
    color: rgba(114, 116, 119, 1);
    font-weight: bold;
    font-size: 18px;
    @media @max750 {
      height: 50px;
      font-size: 13px;
    }
  }
  .table_body {
    height: 80px;
    margin-top: 4px;
    font-size: 18px;
    border: transparent;
    background: #101115;
    border-radius: 7px;
    @media @max750 {
      height: 50px;
      font-size: 12px;
    }
    th {
      font-weight: 500;
      color: #d1d1d1;
    }
    .name {
      padding: 0 10px;
      max-width: 140px;
      @media @max750 {
        max-width: 60px;
        padding: 0 4px;
      }
    }
    .time {
      max-width: 100px;
      @media @max750 {
        max-width: 60px;
      }
    }
    .common_price {
      justify-content: center;
      ::v-deep {
        img {
          width: 18px;
          @media @max750 {
            width: 12px;
          }
        }
        span {
          font-size: 18px;
          @media @max750 {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.img {
  width: 80px;
  @media @max750 {
    width: 35px;
  }
}
.fail {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & > div {
    text-align: center;
  }
}
.table_box {
  margin-top: 20px;
  min-height: 200px;
  margin-bottom: 20px;
  @media @max750 {
    min-height: auto;
    margin-top: 4px;
    margin-bottom: 0;
  }
}

@keyframes inlineSun {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    width: 120%;
    height: 120%;
  }
  100% {
    opacity: 1;
  }
}
</style>
